/* eslint-disable */
import React, { Component } from "react";
import { Link } from "gatsby";
import SEO from "../../components/seo";
import "./style.scss";
import { MONTHLY, QUARTERLY, YEARLY } from "../../utils/constants";
import AppStoreButtons from "../../components/AppStoreButtons";
import { brand, images } from "../../utils/assets";
 import { theme } from "../../theme";

class ThankYouPage extends Component {
  componentDidMount() {
    if (typeof window !== "undefined") {
      let planValue = 0;
      switch (`${this.getUrlParam("plan", "none")}`) {
        case MONTHLY:
          planValue = 12.99;
          break;
        case QUARTERLY:
          planValue = 32.99;
          break;
        case YEARLY:
          planValue = 99.99;
          break;
      }
      if (window.fbq != null) {
        fbq("track", "Purchase", {
          value: planValue,
          currency: "GBP",
        });
      }

      if (tap) {
        tap("conversion", `${this.getUrlParam("id", "noid")}`, planValue);
      }
    }
  }

  getUrlVars() {
    var vars = {};
    var parts = window.location.href.replace(
      /[?&]+([^=&]+)=([^&]*)/gi,
      function(m, key, value) {
        vars[key] = value;
      }
    );
    return vars;
  }

  getUrlParam(parameter, defaultvalue) {
    var urlparameter = defaultvalue;
    if (window.location.href.indexOf(parameter) > -1) {
      urlparameter = this.getUrlVars()[parameter];
    }
    return urlparameter;
  }

  render() {
    return (
      <div>
        <SEO title="Thank You" />
        <div className="thank-you__container">
          <img src={images.FLAG01} className="flag-01" alt="" />
          <img src={images.FLAG02} className="flag-02" alt="" />
          <img src={images.FLAG03} className="flag-03" alt="" />
          <div className="thank-you__width-control">
            <img
              src={brand.APP_LOGO}
              className="thank-you__logo"
              alt={brand.NAME}
            />
            <h3>thanks for subscribing and welcome to the family!</h3>
            <Link to="/">
              <h4 className="thank-you__redirect">
                hit this link to go back to the main site
              </h4>
            </Link>
          </div>
          <p>
            download the app and login with the same account you just created
          </p>
          <AppStoreButtons />
        </div>
       </div>
    );
  }
}
export default ThankYouPage;
